import React,{useState,useEffect} from "react";
import {Button, Card, CardActions, CardContent, CircularProgress, Dialog, TextField, Typography} from "@mui/material";
import {Navigate} from "react-router-dom";
import axios from "axios";
import bg from '../img/bg.jpg'
import {toast,ToastContainer} from "react-toastify";

const Login=()=>{
    const [errorMsg, setErrorMsg] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [login, setLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const handle_btn_login = () => {
        setLoading(true);
        const data = {
            username: username,
            password: password,
        }
        axios.post("https://khaneaghsat.co/api/login.php", data)
            .then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    if (response.data.error === false) {
                        sessionStorage.setItem("token", response.data.token);
                        setLogin(true);
                    } else
                        toast.error(response.data.message);
                } else {
                    setLoading(false);
                    toast.error("خطا در اتصال به سرور");
                }
            })
            .catch(error => {
                setLoading(false);
                toast.error("خطا در اتصال به سرور");
            })
    }
    const Redirect = () => {
        return (<Navigate replace to="/admin"/>)
    }
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                height: "100vh",
                backgroundImage: "url(" + bg + ")",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}>
            {login ? <Redirect/> : ''}
            <Card sx={{minWidth: 300, padding: 2, margin: 2,backgroundColor:"rgba(255,255,255,0.76)"}} elevation={3}>
                <CardContent>
                    <h1 style={{fontWeight: "bold", width: "100%", textAlign: "center"}}>WELLCOME</h1>
                    <Typography component={"h4"} sx={{mb: 1.5, marginTop: 1}} color="text.secondary">
                        Please Enter The username and password to login admin Dashboard
                    </Typography>
                    <Typography variant="body2">
                        <TextField
                            placeholder="Username"
                            fullWidth
                            type="text"
                            autoComplete="off"
                            onChange={(event) => {
                                setUsername(event.target.value)
                            }}
                            variant="outlined"/>
                        <TextField
                            placeholder={"password"}
                            fullWidth
                            type="password"
                            autoComplete="off"
                            onChange={(event) => {
                                setPassword(event.target.value)
                            }}
                            sx={{marginTop: 1}}
                            variant="outlined"/>
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handle_btn_login}
                        size="large">Login</Button>
                </CardActions>
            </Card>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}
export default Login;