import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./Home";
import "./fonts/font.css";
import Dashboard from "./Admin/Dashboard";
import Login from "./Admin/Login";
const App=()=>{
    return (
        <BrowserRouter>
            <Routes >
                <Route path="/" element={<Home/>}/>
                <Route path="/admin" element={<Dashboard/>}/>
                <Route path="/admin/login" element={<Login/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
